<template>
  <v-card>
    <link href="https://unpkg.com/vue-croppa/dist/vue-croppa.min.css" rel="stylesheet" type="text/css" />
    <v-card-title class="headline justify-center">{{ title }}</v-card-title>

    <v-card-text class="d-flex flex-column">
      <p class="text-center" v-t="'profile.info.zoomToCrop'" />
      <div v-if="cropper && cropper.hasImage()" class="d-flex justify-center">
        <v-btn outlined @click="cropper.remove" class="ma-4">
          {{ $t('generic.remove') }}
        </v-btn>
        <v-btn outlined @click="cropper.chooseFile" class="ma-4">
          {{ $t('profile.info.changeFile') }}
        </v-btn>
      </div>
      <v-layout align-center justify-center v-resize="updateDimensions" ref="container">
        <croppa
          v-model="cropper"
          :class="{ rounded: rounded }"
          :width="widthAdj"
          :height="heightAdj"
          :quality="qualityAdj"
          :show-remove-button="false"
          :initial-image="initialImg"
          initial-size="cover"
          :file-size-limit="10000000"
          disable-rotation
          prevent-white-space
          placeholder="Choose an image"
          ref="croppa"
          class="align-self-center"
        />
      </v-layout>
    </v-card-text>

    <v-card-actions>
      <v-spacer />
      <v-btn text @click="close()">
        {{ $t('generic.cancel') }}
      </v-btn>
      <v-btn color="primary" :loading="uploading" :disabled="!uploadable" @click="upload">
        {{ $t('generic.save') }}
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import Vue from 'vue'
import Croppa from 'vue-croppa'
import axios from 'axios'
import errorService from '@/services/error-service'

Vue.use(Croppa)

export default {
  props: {
    title: { type: String, default: 'Select image' },
    width: { type: Number, default: 600 },
    height: { type: Number, default: 400 },
    quality: { type: Number, default: 1.0 },
    initialImg: { type: String, default: null },
    rounded: { type: Boolean, default: false },
    folder1: { type: String, default: '' },
    folder2: { type: String, default: '' },
    folder3: { type: String, default: '' },
    filename: { type: String, default: '' },
  },
  data() {
    return {
      cropper: null,
      uploading: false,
      widthAdj: 1,
      heightAdj: 1,
      qualityAdj: 1,
    }
  },
  computed: {
    ...mapState(['user']),
    uploadable() {
      return this.cropper && this.cropper.hasImage() && !this.uploading
    },
    uploadUrl() {
      return `${process.env.VUE_APP_URL_UPL}/upload`
    },
  },
  created() {
    this.widthAdj = this.width
    this.heightAdj = this.height
  },
  mounted() {
    this.updateDimensions()
    this.removeGreyBottomBorder()
  },
  methods: {
    ...mapActions('dialog', ['close']),
    updateDimensions() {
      const wContainer = this.$refs.container.clientWidth
      if (wContainer < this.width) {
        const scaling = wContainer / this.width
        this.widthAdj = wContainer
        this.heightAdj = this.height * scaling
        this.qualityAdj = this.quality / scaling
        this.removeGreyBottomBorder()
        this.cropper.refresh()
      }
    },
    removeGreyBottomBorder() {
      // Somehow the croppa element is 11px too high...
      this.$refs.croppa.$el.style.maxHeight = this.heightAdj + 'px'
    },
    async upload() {
      if (!this.user.uid) return

      this.uploading = true
      const blob = await this.cropper.promisedBlob('image/png')

      let data = new FormData()
      data.append('folder1', this.folder1)
      data.append('folder2', this.folder2)
      data.append('folder3', this.folder3)
      data.append('filename', this.filename)
      data.append('user_id', this.user.uid)
      data.append('file', blob, 'blobfile.png')

      let config = { headers: { 'Content-Type': 'multipart/form-data' } }

      await axios({ method: 'post', url: this.uploadUrl, data, config })
        .then(this.onUploaded)
        .catch(errorService.toast)
      this.uploading = false
    },
    onUploaded(response) {
      const receivedFilename = response.data
      const imgUrlParts = [ // eslint-disable-line
        process.env.VUE_APP_URL_UPL,
        this.folder1,
        this.folder2,
        this.folder3,
        receivedFilename,
      ]
      const imgUrl = imgUrlParts.filter(s => s).join('/')
      this.close(imgUrl)
    },
  },
}
</script>

<style lang="sass">
.rounded
  &.croppa-container,
  canvas
    border-radius: 100%
</style>
